import styled from 'styled-components';

import { container, space } from 'theme/sizes';
import { white, blue, grey } from 'theme/colors';
import { toRem, breakpoints } from 'utils/mixins';
import { h1 } from '../../theme/typography';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.default};
  width: 100%;
  padding: 0 ${toRem(12)};
  margin: 0 auto ${toRem(60)};

  @media ${breakpoints.laptop} {
    width: calc(100% - 36px);
    margin: ${toRem(80)} auto ${toRem(160)};
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;

  h4 {
    position: relative;
    margin-bottom: 24px;
    text-align: center;

    &::before {
      content: '';
      width: 80%;
      left: 10%;
      height: 6px;
      background-color: rgb(200, 200, 200);
      bottom: 0;
      transform: translateY(8px);
      position: absolute;
      z-index: -1;
    }
  }

  h2,
  h4,
  p {
    max-width: ${container.xxsmall};
    text-align: center;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px 0;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      justify-content: center;
      padding: 0 24px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      span {
        text-align: center;
        margin: unset !important;
        ${h1}

        @media ${breakpoints.laptop} {
          font-size: ${toRem(78)};
          line-height: ${toRem(82)};
          letter-spacing: 0.2px;
        }
      }
    }
  }

  @media ${breakpoints.tablet} {
    ul {
      flex-direction: row;
      li {
        &:not(:last-child) {
          &::after {
            content: '';
            width: 1px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            background-color: rgb(200, 200, 200);
          }
        }
      }
    }
  }
`;
