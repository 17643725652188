import React from 'react';

import HTMLReactParser from 'html-react-parser';
import { GatsbyImage } from 'gatsby-plugin-image';
import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { Wrapper, Row } from './International.styles';

const Banner = (props) => {
  //PROPS
  const { lang } = props;
  //PROPS

  if (lang === 'PT') {
    return (
      <Wrapper>
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                initial={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.5 }}
              >
                <Row>
                  <h4>Presença Global</h4>
                  <h2>Os nossos parceiros internacionais</h2>
                  <p>
                    Nas ultimas duas décadas temos aumentado não só a quantidade
                    de produtos exportados, como também, aumentando o nosso
                    leque de clientes e parceiros internacionais.
                  </p>
                  <img src="./images/map.png" alt="map" />
                </Row>
              </motion.div>
            );
          }}
        </InView>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <InView threshold={0.4} triggerOnce>
        {({ inView, ref, entry }) => {
          return (
            <motion.div
              ref={ref}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
              initial={{ opacity: 0, y: 30 }}
              transition={{ duration: 0.5 }}
            >
              <Row>
                <h4>Global Presence</h4>
                <h2>Our International Partners</h2>
                <p>
                  Over the last two decades, we have not only increased the
                  volume of exported products but also expanded our range of
                  international clients and partners.
                </p>
                <img src="./images/map.png" alt="map" />
              </Row>
            </motion.div>
          );
        }}
      </InView>
    </Wrapper>
  );
};

export default Banner;
