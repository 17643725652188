import React from 'react';

import { motion } from 'framer-motion';
import { graphql } from 'gatsby';

import Banner from 'components/banner';
import VideoBanner from 'components/videobanner';
import Stats from 'components/stats';
import ContactForm from 'components/contact-form';
import International from 'components/internacional';

import { pageAnims } from 'theme/animations';
import { grey } from 'theme/colors';

const TemplateHomepage = (props) => {
  //PROPS
  const {
    data: {
      page: {
        homepage: { videobanner, videotext, videotitle, banner },
      },
    },
    lang,
  } = props;
  //PROPS

  //DEFS
  const videoData = { videoBanner: videobanner, videotext, videotitle };
  //DEFS

  return (
    <>
      <motion.div
        style={{ background: grey.default, minHeight: '100vh' }}
        variants={pageAnims}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {videobanner && <VideoBanner data={videoData} full={true} />}

        <International lang={lang} />
        <Stats lang={lang} />

        {banner.map((bannerItem, index) => (
          <Banner data={{ banner: bannerItem }} key={index} />
        ))}
      </motion.div>
      <ContactForm lang={lang} />
    </>
  );
};

export const query = graphql`
  query Home($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      homepage {
        videotext
        videotitle
        videobanner {
          mediaItemUrl
        }
        banner {
          title
          text
          imageFirst
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        # selectedProducts {
        #   ... on WpProduto {
        #     title
        #     seo {
        #       slug
        #     }
        #     product {
        #       text
        #       imagem {
        #         altText
        #         srcSet
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`;

export default TemplateHomepage;
