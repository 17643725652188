import React from 'react';

import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { Wrapper, Row } from './Stats.styles';

const Stats = (props) => {
  //PROPS
  const { lang } = props;
  //PROPS

  console.log(lang);
  if (lang === 'PT') {
    return (
      <Wrapper>
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                initial={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.5 }}
              >
                <Row>
                  <h4>Produção</h4>
                  <h2>O poder dos números</h2>
                  <p>
                    Demarcamos por juntarmos um grande poder produtivo com um
                    elevado grau de qualidade.
                  </p>
                  <ul>
                    <li>
                      <span>500 mil</span>
                      <p>almofadas vendidas por ano</p>
                    </li>
                    <li>
                      <span>+ 1M de km</span>
                      <p>de tecido gasto na produção</p>
                    </li>
                  </ul>
                </Row>
              </motion.div>
            );
          }}
        </InView>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <InView threshold={0.4} triggerOnce>
        {({ inView, ref, entry }) => {
          return (
            <motion.div
              ref={ref}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
              initial={{ opacity: 0, y: 30 }}
              transition={{ duration: 0.5 }}
            >
              <Row>
                <h4>Production</h4> <h2>The Power of Numbers</h2>
                <p>
                  We stand out by combining great production power with a high
                  level of quality.
                </p>
                <ul>
                  <li>
                    <span>500 thousand</span> <p>pillows sold per year</p>
                  </li>
                  <li>
                    <span>+ 1M km</span> <p>of fabric used in production</p>
                  </li>
                </ul>
              </Row>
            </motion.div>
          );
        }}
      </InView>
    </Wrapper>
  );
};

export default Stats;
